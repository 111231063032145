import { useMemo, useState } from "react";
import Filter from "components/filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import api from "service";
import MetricCard from "./MetricCard";
import XMLViewer from "react-xml-viewer";

const SearchFimpe = ({ module }) => {
  const [data, setData] = useState(null);

  const success = useMemo(() => {
    if (data) {
      return data.success;
    }
    return null;
  }, [data]);

  const error = useMemo(() => {
    if (data) {
      return data.error;
    }
    return null;
  }, [data]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);

  const handleFilter = () => {
    setLoading(true);
    return api
      .get(`/report/search-fimpre?start=${startDate}&end=${endDate}`)
      .then((res) => {
        if (res) setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <Filter
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={handleFilter}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <div className="pt-10">
          <div className="mb-8 grid grid-cols-1 gap-6 md:grid-cols-3">
            <MetricCard
              title="Consultadas con éxito"
              change="$234k"
              value={success?.total}
              period={startDate}
            />
            <MetricCard
              title="Consultadas con error"
              change="$541k"
              period={startDate}
              value={error?.total}
            />
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-4">
              {success
                ? success.data.map((item, index) => {
                    const arryA = item.message.split("|");
                    return (
                      <div
                        key={index}
                        className="border-sky-500 rounded-lg border-2 border-solid p-2"
                      >
                        <span className="text-[16px] font-bold">
                          Fecha: {format(item.timestamp, "yy-MM-dd")}
                        </span>
                        <XMLViewer xml={arryA[3]} />
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="flex flex-col gap-4">
              {error
                ? error.data.map((item, index) => {
                    const arryA = item.message.split("|");
                    return (
                      <div
                        key={index}
                        className="border-sky-500 rounded-lg border-2 border-solid p-2"
                      >
                        <span className="text-[16px] font-bold">
                          Fecha: {format(item.timestamp, "yy-MM-dd")}
                        </span>
                        <XMLViewer xml={arryA[3]} />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFimpe;
