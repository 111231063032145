const inputsList = [
  {
    type: "text",
    label: "NOMBRE",
    prop: "nombre",
  },
  {
    type: "text",
    label: "Segundo nombres",
    prop: "secondName",
  },
  {
    type: "text",
    label: "PRIMER APELLIDO",
    prop: "apellidoP",
  },
  {
    type: "text",
    label: "SEGUNDO APELLIDO",
    prop: "apellidoM",
  },
  {
    type: "text",
    label: "FECHA DE NACIMIENTO",
    prop: "fechaNacimiento",
  },
  {
    type: "text",
    label: "CURP",
    prop: "curp",
  },
  {
    type: "text",
    label: "RFC",
    prop: "rfc",
  },
  {
    type: "text",
    label: "ESTADO CIVIL",
    prop: "estadoCivil",
  },
  {
    type: "text",
    label: "SEXO",
    prop: "genero",
  },
];

const inputListAddress = [
  {
    type: "text",
    label: "CALLE",
    prop: "calle",
  },
  {
    type: "text",
    label: "NO EXTERIOR",
    prop: "nExterior",
  },
  {
    type: "text",
    label: "NO INTERIOR",
    prop: "nInterior",
  },
  {
    type: "text",
    label: "COLONIA/URBANIZACIÓN",
    prop: "colonia",
  },
  {
    type: "text",
    label: "CÓDIGO POSTAL",
    prop: "codigoPostal",
  },
  {
    type: "text",
    label: "DELEGACIÓN/MUNICIPIO",
    prop: "delegacion",
  },
  {
    type: "text",
    label: "CIUDAD/POBLACIÓN",
    prop: "ciudad",
  },
  {
    type: "text",
    label: "ESTADO/ENTIDAD FEDERATIVA",
    prop: "estado",
  },
];

const arrrayDos = [
  {
    type: "text",
    label: "TELÉFONO CELULAR",
    prop: "phone",
  },
  {
    type: "text",
    label: "CORREO ELECTRÓNICO",
    prop: "email",
  },
  {
    type: "text",
    label: "FECHA DE INGRESO LABORAL *",
    prop: "ingreso",
  },
  {
    type: "text",
    label: "NÚMERO DE EMPLEADO *",
    prop: "numero_empleado",
  },
  {
    type: "text",
    label: "CONVENIO DEL CLIENTE *",
    prop: "convenio",
  },
  {
    type: "text",
    label: "DEPENDENCIA",
    prop: "dependencia",
  },
  {
    type: "text",
    label: "UNIVERSO *",
    prop: "universo",
  },
  {
    type: "text",
    label: "TIPO DE NÓMINA",
    prop: "nomina",
  },
  {
    type: "text",
    label: "SECC SINDICAL *",
    prop: "secc_sindical",
  },
  {
    type: "text",
    label: "TIPO DE LEY *",
    prop: "tipo_ley",
  },
  {
    type: "text",
    label: "COMISIÓN DE SINDICAL *",
    prop: "comision_sindical",
  },
  {
    type: "text",
    label: "NIVEL *",
    prop: "nivel",
  },
  {
    type: "text",
    label: "CODIGO DE PUESTO *",
    prop: "codigo_puesto",
  },
  {
    type: "text",
    label: "NÚMERO DE PLAZA *",
    prop: "numero_plaza",
  },
  {
    type: "text",
    label: "ZONA PAGADORA *",
    prop: "zona_pagadora",
  },
  {
    type: "text",
    label: "UNIDAD ADMINISTRATIVA *",
    prop: "unidad_administrativa",
  },

  {
    type: "text",
    label: "FOLIO FISCAL",
    prop: "folioFiscal",
  },
];

const documentsArray = [
  {
    text: "INE FRENTE",
    type: "ine_front",
    disableBtn: true,
  },
  {
    text: "INE REVERSO",
    type: "ine_reverso",
    disableBtn: true,
  },
  {
    text: "SELFIE VERIDAS",
    type: "selfie_veridas",
    disableBtn: true,
  },
  {
    text: "SELFIE PRE-CONTRATO",
    type: "selfie-pre-contrato",
    disableBtn: true,
  },
  {
    text: "VIDEO DE ACEPTACION",
    type: "vide_aceptacion",
  },
  {
    text: "Penúltimo recibo de nomina ( front )",
    type: "penultimo_nomina_front",
  },
  {
    text: "Penúltimo recibo de nomina ( reverso )",
    type: "penultimo_nomina_reveso",
  },
  {
    text: "Ultimo recibo de nómina",
    type: "ultimo_nomina",
  },
  {
    text: "Último recibo de nómina ( reverso )",
    type: "ultimo_nomina_reveso",
  },
  {
    text: "Carátula estado de cuenta",
    type: "caratula_estado_de_cuenta",
  },
  {
    text: "Documento adicional 1",
    type: "documento_adicional_uno",
  },
  {
    text: "Documento adicional 2",
    type: "documento_adicional_dos",
  },
];

export { inputsList, inputListAddress, arrrayDos, documentsArray };
