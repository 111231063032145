import ButtonGeneral from "components/button-general";
import ModalGeneral from "components/modal";
import React, { useState } from "react";
import toast from "react-hot-toast";
import api from "../../../../service";

export const Observaciones = ({
  close,
  token,
  defaultText,
  title,
  isDevoluciones,
  document,
  rol,
  module,
}) => {
  const [formData, setFormData] = useState(defaultText);

  const Save = async () => {
    if (document && formData.length > 0) {
      httpApi(formData);
    }
  };

  const httpApi = async (textComment) => {
    const { id } = document;
    const endpoint = `/solicitudes/${id}/comments`;
    const response = await api
      .put(endpoint, { comment: textComment })
      .catch((error) => {
        return null;
      });
    if (response) {
      close();
      toast.success("Se ha creado exitosamente el comentario.", {
        duration: 3000,
        position: "top-right",
      });
    } else {
      toast.error("Ha ocurrido un error", {
        duration: 3000,
        position: "top-right",
      });
    }
  };

  return (
    <ModalGeneral title={title} close={close}>
      <div className="flex flex-row items-center justify-center gap-2 px-2 py-2">
        <textarea
          id="message"
          rows="4"
          class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Ingrese sus comentarios"
          value={formData}
          onChange={(event) => {
            setFormData(event.target.value);
          }}
        ></textarea>
      </div>
      <div className="flex justify-end p-2 pt-2">
        <ButtonGeneral
          visible={[1, 4, 6, 7].includes(rol)}
          handleClick={Save}
          className={"w-[250px]"}
        >
          Guardar comentario
        </ButtonGeneral>

        {defaultText && isDevoluciones && (
          <ButtonGeneral
            visible={[1, 4, 6, 7].includes(rol)}
            handleClick={() => httpApi("")}
            className={"w-[250px]"}
          >
            Validar documento
          </ButtonGeneral>
        )}
      </div>
    </ModalGeneral>
  );
};
