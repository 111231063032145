export const MetricCard = ({
  title,
  value,
  change,
  period = "vs last month",
}) => {
  return (
    <div className="text-primary-foreground animate-fade-in bg-green-300 p-6">
      <div className="mb-4 flex items-start justify-between">
        <h3 className="text-sm font-medium opacity-80">{title}</h3>
      </div>
      <div className="space-y-2">
        <p className="text-3xl font-bold">{value}</p>
        <div className="flex items-center gap-2 text-sm">
          <span className="opacity-50">{period}</span>
        </div>
      </div>
    </div>
  );
};

export default MetricCard;
