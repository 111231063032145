import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotificacion } from "../../../redux/slice/auth";
import useLogTracker from "hooks/useLogTracker";
import api from "../../../service";

const bgs = {
  autorizada: "bg-green-200",
  Devuelta: "bg-yellow-100",
  reasignacion: "bg-yellow-100",
  cancelada: "bg-yellow-100",
  solicitada: "bg-yellow-100",
  "por-atender": "bg-yellow-100",
};

const Dashboard = () => {
  const { token } = useSelector((state) => state.auth);
  const track = useLogTracker("Dashboard");
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (token) {
      getDashboard();
      getCount();
    }
  }, [token]);

  const getDashboard = () => {
    const endpoint = `/solicitudes/dashboard`;
    setLoading(true);
    api
      .get(endpoint)
      .then((json) => {
        if (json) {
          setData(json.filter((a) => a.estatus !== "captura"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCount = () => {
    const endpoint = `/solicitudes/count-sidebar`;
    api.get(endpoint).then((json) => {
      if (json) {
        dispatch(setNotificacion(json));
      }
    });
  };

  return (
    <div className="grid grid-cols-1 gap-6 p-6 lg:grid-cols-1">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="mb-6">
          <h1 className="text-[34px] text-lg font-bold">Bienvenid@</h1>

          <h2 className="mt-10 text-lg font-bold">Reporte diario</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {loading ? (
            <>
              <div
                role="status"
                class="animate-pulse space-y-8 md:flex md:items-center md:space-x-8 md:space-y-0 rtl:space-x-reverse"
              >
                <div class="flex h-48 w-full items-center justify-center rounded bg-gray-300 dark:bg-gray-700 sm:w-96"></div>
              </div>
            </>
          ) : data ? (
            data.map((a) => {
              return (
                <div
                  className={`rounded-lg border-[1px] border-solid border-brand-300 bg-white p-4 shadow-md`}
                >
                  <p className="capitalize text-gray-600">{a.estatus}</p>
                  <h3 className="text-2xl font-bold">{a.conteo}</h3>
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
