import { useState } from "react";
import Filter from "components/filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import api from "service";
import Grid from "components/grid";
import { FaFilePdf } from "react-icons/fa";

const Pagares = ({ module }) => {

  const [data, setData] = useState([]);

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);

  const handleFilter = () => {
    setLoading(true);
    return api
      .get(`/report/pagare-documents?start=${startDate}&end=${endDate}`)
      .then((res) => {
        if (res) setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="mt-5 grid h-full grid-cols-1 ">
      <Filter
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={handleFilter}
        exportColumns={[
          { field: "id", headerName: "FOLIO ID" },
          { field: "zellId", headerName: "ZELL ID" },
          { field: "documentId", headerName: "DOCUMENT ID" },
        ]}
        dataExport={data}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={[
            { name: "FOLIO MD" },
            { name: "ZELL ID" },
            { name: "DOCUMENT ID" },
            { name: "ARCHIVO" },
          ]}
          data={data ? data : []}
          ComponentRow={({ item }) => {
            return (
              <>
                <div className="table-cell p-2">{item.id}</div>
                <div className="table-cell p-2">{item.zellId}</div>
                <div className="table-cell p-2">{item.documentId}</div>
                <div className="table-cell p-2">
                  <a href={item.file} target="_blank">
                    <FaFilePdf />
                  </a>
                </div>
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default Pagares;
