import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import useSignalR from "hooks/useSignalR";


const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/auth" replace />;
  }
  return children;
};

const App = () => {
  useSignalR();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleSessionEnd = () => {
      console.log("cerrando sesion");
      //dispatch(logout());
    };

    window.addEventListener("beforeunload", handleSessionEnd);

    return () => {
      window.removeEventListener("beforeunload", handleSessionEnd);
    };
  }, [dispatch]);
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute token={token}>
            <AdminLayout />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate to="/auth" replace />} />
    </Routes>
  );
};

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={"Cargando store"} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}
