import { FaRegComments } from "react-icons/fa";
import { MdWarning } from "react-icons/md";

const ItemImage = ({
  document,
  image,
  OpenModalComentarios,
  setSelectedImage,
}) => {
  return (
    <div className="group relative cursor-pointer overflow-hidden transition-all duration-300 hover:shadow-md">
      <div className="flex items-center gap-4 p-4">
        <div className="relative h-16 w-16 overflow-hidden rounded-lg bg-gray-100">
          <img
            src={document?.file}
            alt={document?.type}
            className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
            onClick={() => {
              setSelectedImage(document);
            }}
          />
        </div>
        <div
          className="flex-1"
          onClick={() => {
            setSelectedImage(document);
          }}
        >
          <h3 className="text-sm font-medium text-gray-900">{image.text}</h3>
          <p className="mt-1 text-xs text-gray-500">Click para expandir</p>
        </div>
        {!image.disableBtn && (
          <button
            onClick={() =>
              OpenModalComentarios({
                ...document,
                title: image.text,
              })
            }
            title="Agregar comentario"
            className={`h-5 w-5 text-blue-400 text-gray-500  transition-opacity duration-300`}
          >
            {document?.comments ? (
              <MdWarning size={22} />
            ) : (
              <FaRegComments size={22} />
            )}
          </button>
        )}
      </div>
    </div>
  );
};


export default ItemImage