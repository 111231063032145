import { useEffect, useMemo, useState } from "react";
import Filter from "components/filter";
import {
  differenceInYears,
  endOfMonth,
  format,
  parse,
  startOfMonth,
} from "date-fns";
import ViewDatos from "./view-datos";
import { AsignarUsuario } from "./asignar-usuarios";
import toast from "react-hot-toast";
import { AcuseCep } from "./acuse-cep";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName, setNotificacion } from "../../../redux/slice/auth";
import ModalGeneral from "components/modal";
import SimuladorC from "../simulador/components/simulador";
import ListSolicitudes from "./list-solicitudes";
import Audit from "../audit";
import useLogTracker from "hooks/useLogTracker";
import api from "../../../service";
import { useLocation, useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';

const Solicitudes = ({ status, module }) => {
  const { registerCustomEvent } = useLogTracker(module);

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [modalAsignar, setModalAsignar] = useState(false);
  const [idGeneralSeleccionado, setidGeneralSeleccionado] = useState("");
  const [modalAcuse, setModalAcuse] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showSimulador, setShowSimulador] = useState(null);
  const [tipo, setTipo] = useState("");
  const [newOferta, setNewOferta] = useState();
  const [textStatus, setTextStatus] = useState("");
  const [idLog, setIdLog] = useState(null);
  const { REACT_APP_URL_BASE } = process.env;
  const { token, rol, indexUpdate } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const handleFilter = () => {
    getAll();
  };

  const getValueByPath = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  const dataGrid = useMemo(() => {
    if (!data) return [];

    const lowerTextSearch = textSearch?.toLowerCase() || "";
    const lowerTextStatus = textStatus?.toLowerCase() || "";

    return data.filter((item) => {
      // Filtrar por el estado
      if (lowerTextStatus) {
        const statusValue = getValueByPath(item, "general.estatus");
        if (
          !statusValue ||
          statusValue.toString().toLowerCase() !== lowerTextStatus
        ) {
          return false;
        }
      }

      return ["general.id", "general.estatus", "general.nombre"].some((key) => {
        const value = getValueByPath(item, key);
        if (value !== undefined && value !== null) {
          const valueStr = value.toString().toLowerCase();
          return valueStr.includes(lowerTextSearch);
        }
        return false;
      });
    });
  }, [data, textSearch, textStatus]);

  const closeModal = () => {
    setModalOpen(false);
    getAll();
    setFormData({});
  };

  const closeModalAsignar = () => {
    setModalAsignar(false);
    setidGeneralSeleccionado(null);
    getAll();
  };

  useEffect(() => {
    if (module) {
      dispatch(setHeaderName(module));
    }
  }, [module, dispatch]);

  
  useEffect(() => {
    if (indexUpdate > 0) {
      getAll();
      getCount();
    }
  }, [indexUpdate]);

  const getAll = () => {
    const query = `start=${startDate}&end=${endDate}&status=${status ?? ""}`;
    const endpoint = `/solicitudes?${query}`;

    setLoading(true);
    api
      .get(endpoint)
      .then((json) => {
        if (Array.isArray(json)) {
          setData(json);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCount = () => {
    const endpoint = `/solicitudes/count-sidebar`;
    api.get(endpoint).then((json) => {
      if (json) {
        dispatch(setNotificacion(json));
      }
    });
  };

  // const openModal = (item) => {
  //   setModalOpen(true);
  //   setFormData(item);
  // };

  const handleView = async (item) => {
    if (item.general.estatus === "solicitada") {
      const endpoint = `/solicitudes/${item.general.id}/change-status`;
      await api
        .put(endpoint, {
          textStatus: "proceso",
        })
        .finally(() => {
          getAll();
        });
    }
    const jsonString = JSON.stringify({
      id: item.general.id,
    });
    const base64String = Buffer.from(jsonString).toString("base64");

    const origin = location.pathname;
    const urlNavigate = `/admin/solcitudes/gcdmx/details?query=${base64String}&moduleOrigin=${origin}`;
    navigate(urlNavigate);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataGrid.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(dataGrid.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <Filter
          values={{
            startDate,
            endDate,
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          showFilterText={true}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          showStatus={true}
          textStatus={textStatus}
          setTextStatus={setTextStatus}
        />

        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <>
            <div className="mt-5 flex flex-col gap-5">
              {currentItems.map((item) => {
                return (
                  <ListSolicitudes
                    item={item}
                    rol={rol}
                    status={status}
                    handleView={handleView}
                    setModalAcuse={setModalAcuse}
                    setidGeneralSeleccionado={setidGeneralSeleccionado}
                    setModalAsignar={setModalAsignar}
                    setShowSimulador={setShowSimulador}
                    token={token}
                    urlBase={REACT_APP_URL_BASE}
                    onRefresh={getAll}
                    onViewLogs={(id) => {
                      setIdLog(id);
                    }}
                    registerCustomEvent={registerCustomEvent}
                  />
                );
              })}
            </div>
            <div className="mt-4 flex items-center justify-start">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="mr-2 rounded-l bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
              >
                <GrFormPrevious />
              </button>
              {Array.from(Array(totalPages).keys()).map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`mr-2 rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
                    pageNumber + 1 === currentPage ? "bg-blue-700" : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= dataGrid.length}
                className="ml-2 rounded-r bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
              >
                <MdNavigateNext />
              </button>
              <span className="ml-2">
                Registros{" "}
                <span className="font-bold">{data && data.length}</span>{" "}
              </span>
            </div>
          </>
        )}
      </div>

      {modalOpen && (
        <ViewDatos
          id={formData.general.id}
          close={closeModal}
          rol={rol}
          registerCustomEvent={registerCustomEvent}
          module={module}
        />
      )}
      {modalAsignar && (
        <AsignarUsuario
          id={idGeneralSeleccionado}
          close={closeModalAsignar}
          registerCustomEvent={registerCustomEvent}
        />
      )}

      {modalAcuse && (
        <AcuseCep
          clave={idGeneralSeleccionado}
          close={() => {
            setModalAcuse(false);
          }}
          token={token}
        />
      )}
      {showSimulador && (
        <ModalGeneral
          title="Recalcular oferta"
          close={() => {
            setShowSimulador(null);
            setNewOferta(null);
          }}
        >
          <div className="m-4 flex flex-row gap-6">
            <div className="flex flex-col gap-1">
              <div className="flex flex-col">
                <label htmlFor="">Nomina: </label>
                <span className="font-bold">{showSimulador.nomina}</span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Universo</label>
                <span className="font-bold">{showSimulador.universo}</span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Curp</label>
                <span className="font-bold">{showSimulador.curp}</span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Rfc</label>
                <span className="font-bold">{showSimulador.rfc}</span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Ficha</label>
                <span className="font-bold">
                  {showSimulador.numero_empleado}
                </span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Genero</label>
                <span className="font-bold">{showSimulador.genero}</span>
              </div>
              <div className="flex flex-col ">
                <label htmlFor="">Tipo Ley</label>
                <span className="font-bold">{showSimulador.tipo_ley}</span>
              </div>
              <div className="flex flex-col">
                <label htmlFor="">Tipo</label>
                <select
                  name="tipo"
                  id="tipo"
                  className="rounded-lg p-1"
                  value={tipo}
                  onChange={(e) => {
                    setTipo(e.target.value);
                  }}
                >
                  <option value={""}>Selecciona el tipo</option>
                  <option value={"Nuevo"}>Nuevo</option>
                  <option value={"Adicional"}>Adicional</option>
                  <option value={"Refinanciamiento"}>Refinanciamiento</option>
                </select>
              </div>
              <button
                className="mt-10 rounded-[10px] bg-brand-500 p-2 text-white"
                disabled={loading}
                onClick={() => {
                  const fechaActual = new Date();
                  const fechaNacimiento = parse(
                    showSimulador.fechaNacimiento,
                    "dd/MM/yyyy",
                    new Date()
                  );
                  const edad = differenceInYears(fechaActual, fechaNacimiento);
                  const formato = "dd/MM/yyyy"; // Formato de la fecha
                  const fechaConvertida = format(
                    parse(showSimulador.fechaNacimiento, formato, new Date()),
                    "yyyy-MM-dd"
                  );

                  const params = {
                    curp: showSimulador.curp,
                    nomina: showSimulador.nomina,
                    ficha: showSimulador.numero_empleado,
                    rfc: showSimulador.rfc,
                    universo: showSimulador.universo,
                    edad: edad,
                    genero: showSimulador.genero,
                    tipoLey: showSimulador.tipo_ley,
                    sindicato: showSimulador.secc_sindical,
                    tipo: tipo,
                    dateBirthDay: fechaConvertida,
                  };

                  const query = Object.keys(params)
                    .map((key) => {
                      const value = encodeURIComponent(params[key]);
                      return `${encodeURIComponent(key)}=${value}`;
                    })
                    .join("&");

                  const options = {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + token,
                      "Content-Type": "application/json",
                      module,
                    },
                  };
                  const endpoint = `${REACT_APP_URL_BASE}/solicitudes/oferta-credito?${query}`;
                  fetch(endpoint, options)
                    .then((res) => res.json())
                    .then((json) => {
                      if (json.message) {
                        return toast.success(json.message, {
                          duration: 3000,
                          position: "top-right",
                        });
                      }
                      console.log(json);
                      setNewOferta(json);
                      return json;
                    })
                    .finally(() => {
                      getAll();
                    });
                }}
              >
                {loading ? "CALCULANDO" : "RECALCULAR"}
              </button>
            </div>
            <SimuladorC
              oferta={newOferta}
              token={token}
              id={showSimulador.id}
              callBack={() => {
                setShowSimulador(null);
                setNewOferta(null);
                getAll();
              }}
            />
          </div>
        </ModalGeneral>
      )}

      {idLog && (
        <ModalGeneral
          close={() => {
            setIdLog(null);
          }}
          title={`Logs de la solicitud ${idLog}`}
        >
          <div className="px-10">
            <Audit id={idLog} />
          </div>
        </ModalGeneral>
      )}
    </div>
  );
};

export default Solicitudes;
