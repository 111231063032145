import { format } from "date-fns";
import CreateExcel from "excel/excel";
import { useRef } from "react";
import Swal from "sweetalert2";

const Filter = ({
  values,
  dataExport,
  setStartDate,
  setEndDate,
  handleFilter,
  textSearch,
  setTextSearch,
  showFilterText,
  exportColumns,
  handleAdd,
  titleAdd,
  textStatus,
  setTextStatus,
  showStatus,
}) => {
  const refBtn = useRef();

  return (
    <div className="mt-8 flex flex-row items-center justify-between">
      <div>
        {setStartDate && (
          <input
            type="date"
            value={values?.startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="mr-2 rounded-md border border-gray-300 p-2"
          />
        )}
        {setEndDate && (
          <input
            type="date"
            value={values?.endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="mr-2 rounded-md border border-gray-300 p-2"
          />
        )}
        {handleFilter && (
          <button
            ref={refBtn}
            onClick={async () => {
              if (refBtn.current) {
                refBtn.current.disabled = true;
                if (handleFilter) {
                  await handleFilter();
                }
                refBtn.current.disabled = false;
              }
            }}
            className="hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 disabled:cursor-not-allowed disabled:bg-gray-400 dark:bg-brand-500 dark:text-white"
          >
            Filtrar
          </button>
        )}
        {exportColumns && (
          <button
            onClick={() => {
              if (!dataExport || dataExport.length === 0) {
                return Swal.fire({
                  type: "waring",
                  title: "Advertencia",
                  text: "No hay información para exportar",
                });
              }

              if (!exportColumns || exportColumns.length === 0) {
                return Swal.fire({
                  type: "waring",
                  title: "Advertencia",
                  text: "No hay columnas para exportar. Contarta a soporte",
                });
              }

              const url = window.location.href;
              const partes = url.split("/");
              const ultimaParte = partes[partes.length - 1];
              const excel = new CreateExcel(
                `md_${ultimaParte}_${format(new Date(), "yyyy_MM_dd")}`,
                exportColumns,
                dataExport
              );
              excel.createDefaultExcel();
            }}
            className=" hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover w-[200px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
          >
            Descargar excel
          </button>
        )}

        {showStatus && (
          <select
            className="ml-3 mr-2 rounded-md border border-gray-300 p-2"
            value={textStatus}
            onChange={(e) => {
              setTextStatus(e.target.value);
            }}
          >
            <option value="">Todos</option>
            <option value="proceso">Proceso</option>
            <option value="solicitada">Solicitada</option>
            <option value="autorizada">Autorizada</option>
          </select>
        )}

        {showFilterText && (
          <input
            type="text"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            className="ml-3 mr-2 w-[250px] rounded-md border border-gray-300 p-2"
            placeholder="Buscar"
          />
        )}
      </div>

      {handleAdd && (
        <button
          onClick={handleAdd}
          className=" hover:bg-brand-500-hover active:bg-brand-500-hover dark:hover:bg-brand-500-hover dark:active:bg-brand-500-hover w-[130px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-brand-500 dark:text-white"
        >
          {titleAdd ?? "Crear usuario"}
        </button>
      )}
    </div>
  );
};

export default Filter;
