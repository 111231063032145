// hooks/useSignalR.js
import { useEffect, useRef } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import sound1 from "../assets/sound/new_1.mp3";
import sound2 from "../assets/sound/not_2.mp3";
import sound3 from "../assets/sound/not_3.mp3";
import { setUpdatePage } from "../redux/slice/auth";

const useSignalR = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { REACT_APP_URL_BASE } = process.env;
  const audio1 = new Audio(sound1);
  const audio2 = new Audio(sound2);
  const audio3 = new Audio(sound3);
  const timeoutIdRef = useRef(null);

  const playSound = (audio) => {
    audio.play();
    timeoutIdRef.current = setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 2000);
  };

  const handleShowMessage = () => {
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } ring-black pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-opacity-5`}
      >
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-900">Notificación</p>
              <p className="mt-1 text-sm text-gray-500">
                Llego una nueva solicitud.
              </p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (!token) {
      console.log("No hay token JWT, no se establece la conexión a SignalR");
      return;
    }

    const $url = REACT_APP_URL_BASE.slice(0, -4);
    const connection = new HubConnectionBuilder()
      .withUrl(`${$url}/real-time`, {
        accessTokenFactory: () => token,
        skipNegotiation: false,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => {
        console.log("Conexión a SignalR establecida");
        connection.on("ReceiveMessage", (user, message) => {
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }

          if (typeof message === "string") {
            const { type } = JSON.parse(message);
            switch (type) {
              case "solicitada":
                handleShowMessage();
                playSound(audio1);
                break;
              case "cancelada":
              case "reasignacion":
              case "por-atender":
              case "Devuelta":
              case "proceso":
                playSound(audio2);
                break;
              case "firma":
                playSound(audio3);
                break;
              default:
                break;
            }
          }

          dispatch(setUpdatePage(1));
        });
      })
      .catch((error) => console.error("Error al conectar a SignalR:", error));

    return () => {
      connection.stop();
    };
  }, [token, dispatch]);
};

export default useSignalR;
